<template>
  <div id="app">
    <router-view />
    <!-- <Home></Home> -->
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import home from "./views/home";

export default {
  name: "App",
  components: {
    // Home: home,
  },
};
</script>

