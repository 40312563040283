import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import Layout from "@/layout";

export const asyncRoutes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home"),
      },
    ],
  },
  {
    path: "/",
    component: Layout,
    redirect: "/task",
    children: [
      {
        path: "task",
        component: () => import("@/views/task"),
      },
    ],
  },
  {
    path: "/",
    component: Layout,
    redirect: "/about",
    children: [
      {
        path: "about",
        component: () => import("@/views/about"),
      },
    ],
  },
  {
    path: "/",
    component: Layout,
    redirect: "/product",
    children: [
      {
        path: "product",
        component: () => import("@/views/product"),
      },
    ],
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: asyncRoutes,
  });

const router = createRouter();

export default router;
