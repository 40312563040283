<template>
  <div>
    <Header ref="header"></Header>
    <div class="container">
      <router-view @changeTab="changeTab"/>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods:{
    changeTab(val){
      this.$refs.header.changeHeadIndex(val);
    }
  }

}
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
}
</style>