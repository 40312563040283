<template>
  <div class="header-wrap" :class="{ 'scroll-top': scrollTop > 500 }">
    <div class="header">
      <div><img src="../assets/logo.png" alt="logo" width="200"/></div>
      <div class="nav">
        <div :class="{ active: headIndex === 0 }" @click="openNewTab('0')">首页</div>
        <div :class="{ active: headIndex === 1 }" @click="openNewTab('1')">任务大厅</div>
        <div :class="{ active: headIndex === 2 }" @click="openNewTab('2')">产品中心</div>
        <div :class="{ active: headIndex === 3 }" @click="openNewTab('3')">管理后台</div>
        <div :class="{ active: headIndex === 4 }" @click="openNewTab('4')">关于我们</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTop: 0,
      headIndex: 0
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    changeHeadIndex(index){
      this.headIndex = index
    },
    openNewTab(tabIndex){
      if(tabIndex==0){
        this.headIndex=0
        this.$router.push('/home')
      }else if(tabIndex==1){
        this.headIndex=1
        this.$router.push('/task')
      }else if(tabIndex==2){
        this.headIndex=2
        this.$router.push('/product')
      }else if(tabIndex==3){
        this.headIndex=3
        window.open("http://byun.shlongyi.cn/login")
      }else if(tabIndex==4){
        this.headIndex=4
        this.$router.push('/about')
      }

    }
  },
};
</script>

<style lang="scss" scoped>
.header-wrap {
  opacity: 0.8;
  background: #233549;
  position: fixed;
  width: 100%;
  z-index: 99;
  transition: all ease-out 0.3s;
}
.scroll-top {
  opacity: 1;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 80;
  //line-height: 80px;
}
.nav {
  display: flex;
  & > div {
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
    min-width: 120px;
    height: 80;
    line-height: 80px;
    cursor: pointer;
  }
  .active {
    background: var(--blue);
    position: relative;
    &::after {
      content: "\e60d";
      font-family: "iconfont" !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      display: block;
      font-size: 12px;
      position: absolute;
      color: #1eb0ff;
      top: 20px;
      left: 0;
      right: 0;
    }
  }
}
</style>