<template>
  <div class="footer">
    <div class="footer-nav">
      <div class="left">
        <div>
          <h1 class="nav-title">关于我们</h1>
          <a href="" class="nav-link">关于我们</a>
        </div>
        <div>
          <h1 class="nav-title">友情链接</h1>
          <a href="" class="nav-link">新业态灵活用工平台</a>
        </div>
        <div>
          <h1 class="nav-title">客服电话：93829789</h1>
          <p class="nav-link">周一至周日8点-22点</p>
        </div>
      </div>
      <div class="qrcode"><img src="../assets/home/qrcode.png" alt="" /></div>
    </div>
    <div class="copyright">
      Copyright 2012-2018 www.baiyiyunchuang.com 版权所有 沪ICP备11008428号
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  background: #233549;
}
.footer-nav {
  padding: 35px 0;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  .left {
    display: flex;
  }
  .left > div {
    width: 210px;
  }
  p {
    margin: 0;
    padding-top: 6px;
  }
}
.nav-title {
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 20px;
}
.nav-link {
  opacity: 0.4;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 14px;
}
.qrcode {
  width: 95px;
  height: 95px;
  img {
    width: 100%;
    height: 100%;
  }
}
.copyright {
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 20px 0 38px 0;
}
</style>