import Vue from "vue";
import App from "./App.vue";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import { Swiper as SwiperClass, Pagination, Autoplay, Navigation } from "swiper";
import router from "./router";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
SwiperClass.use([Pagination, Autoplay, Navigation]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
import "./styles/index.scss";
import "./assets/iconfont/iconfont.css";

Vue.component("Header", Header);
Vue.component("Footer", Footer);
Vue.component("Swiper", Swiper);
Vue.component("SwiperSlide", SwiperSlide);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
